import React from "react";
import { Box, ButtonBase } from "@mui/material";
import PublicUserIcon from "../../../images/public-user-icon.svg";

export default function UserIconLink() {
  return (
    <ButtonBase onClick={() => window.location.assign("https://app.getblys.com.au/bookings")}>
      <img src={PublicUserIcon} alt="user-image" />
    </ButtonBase>
  );
}
