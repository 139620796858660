import React from "react";
import { Box, Text } from "../../../v2/Styled";
import * as Styled from "../../../v2/Styled/enum";
import { getValue } from "../../../../utils/object";
import { Colors } from "../../../../constants/colors";
import StatusPill from "../../../StatusPill";
import {
  StripeConnectAccountDetailStatus,
  StripeConnectAccountStatus,
} from "../../../../constants/stripe";

interface Props {
  bankingInformation: Record<string, any>;
  accountStatus?: {
    status: string;
    accountStatus: any;
  };
  hideBadge?: boolean;
}

const BankingInformation = ({ bankingInformation, accountStatus, hideBadge = false }: Props) => {
  const title = getValue(bankingInformation, "title", "");
  const description = getValue(bankingInformation, "description", "");

  const status = getValue(accountStatus, "status");
  const accountActionStatus = getValue(accountStatus, "accountStatus.status");

  const getStatusPillInfo = (status: StripeConnectAccountDetailStatus) => {
    const data = {
      text: "",
      bgColor: Colors.Pale,
    };

    switch (status) {
      case StripeConnectAccountDetailStatus.InReview:
        data.text = "In review";
        data.bgColor = Colors.Pale;
        break;
      case StripeConnectAccountDetailStatus.Verified:
        data.text = "Verified";
        data.bgColor = Colors.Peppermint;
        break;
      case StripeConnectAccountDetailStatus.ActionRequired:
      case StripeConnectAccountDetailStatus.ActionRequiredOverdue:
      default:
        data.text = "Action required";
        data.bgColor = Colors.Pale;
        break;
    }

    return data;
  };

  const { text, bgColor } = getStatusPillInfo(accountActionStatus);

  return (
    <Box
      gap={Styled.Spacing.S6}
      display={Styled.Display.Flex}
      direction={Styled.FlexDirection.Column}
    >
      <Box display={Styled.Display.Flex} gap={Styled.Spacing.S4} flexWrap={Styled.FlexWrap.Wrap}>
        <Text
          color={Colors.NightBlue}
          size={Styled.FontSize.F24}
          font={Styled.FontFamily.Museo}
          weight={Styled.FontWeight.SemiBold}
        >
          {title}
        </Text>
        {status !== StripeConnectAccountStatus.NOT_CONNECTED && !hideBadge ? (
          <StatusPill text={text} bgColor={bgColor} />
        ) : (
          <></>
        )}
      </Box>
      {description ? (
        <Box>
          <Text
            font={Styled.FontFamily.Museo}
            weight={Styled.FontWeight.Regular}
            size={Styled.FontSize.F16}
            lineHeight={Styled.LineHeight.L24}
            color={Colors.Dusk}
          >
            {description}
          </Text>
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default BankingInformation;
