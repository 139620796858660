import React, { useEffect, useState } from "react";
import { Box } from "@material-ui/core";

import Each from "../../../components/Each";
import Button from "../../../components/Button";
import { getValue } from "../../../utils/object";
import { useMobile } from "../../../hooks/mobile";
import Divider from "../../../components/Divider";
import { Colors } from "../../../constants/colors";
import { Address, Recipient } from "../../../models";
import { Text } from "../../../components/v2/Styled";
import { checkIfEmpty } from "../../../utils/object";
import { useAlertStore } from "../../../stores/alert";
import queryClient from "../../../config/queryClient";
import { ButtonType } from "../../../components/Button";
import { fetchLocationTypes } from "../../../stores/types";
import AddressItem from "../../../components/Addresses/AddressItem";
import pixelsService from "../../../services/pixels/pixels.service";
import AddressModal from "../../../components/Addresses/AddressModal";
import ContentModal from "../../../components/Modals/ContentModal/contentModal";
import { key, useGetRecipientAddress } from "../../../hooks/recipient/recipient.hooks";
import { removeUserAddress, updateUserAddress } from "../../../services/users/users.service";
import { ADDRESS_QUERY_KEYS, useIsAddressRecurring } from "../../../hooks/address/address.hooks";
import RecurringAddressUpdateModal from "../../Bookings/Components/RecurringAddressUpdateModal";
import { EmptyDataPlaceHolder } from "../../../components/EmptyDataPlaceholder/EmptyDataPlaceHolder";
import {
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  Spacing,
  TextAlign,
} from "../../../components/v2/Styled/enum";

interface RecipientAddressSectionProps {
  recipient: Recipient;
}

const RecipientAddressSection = ({ recipient }: RecipientAddressSectionProps) => {
  const isMobile = useMobile();

  const { data, isLoading } = useGetRecipientAddress(recipient.id, {});

  const addresses = getValue(data, "data") || [];

  const [selectedAddress, setSelectedAddress] = React.useState<Address | null>(null);
  const [addressModalOpen, setAddressModalOpen] = React.useState(false);
  const [selectedRecurringAddress, setSelectedRecurringAddress] = useState<any>(null);
  const [recurringModalOpen, setRecurringModalOpen] = useState(false);
  const { setErrorMessage, setSuccessMessage } = useAlertStore();
  const [showContentModalForEdit, setShowContentModalForEdit] = useState(false);
  const [showContentModalForDelete, setShowContentModalForDelete] = useState(false);

  const selectedAddressId = selectedAddress ? selectedAddress.id : null;
  const { isFetching: isRecurringCheckLoading, data: hasRecurringBooking } =
    useIsAddressRecurring(selectedAddressId);

  useEffect(() => {
    fetchLocationTypes();
    pixelsService.trackPageView();
  }, []);

  useEffect(() => {
    queryClient.invalidateQueries([ADDRESS_QUERY_KEYS.hasRecurring]);
  }, [selectedAddress]);

  const handleCloseRecurring = () => {
    setRecurringModalOpen(false);
    setAddressModalOpen(false);
    setSelectedAddress(null);
    setSelectedRecurringAddress(null);
  };

  const handleSelectedForEdit = () => {
    setAddressModalOpen(true);
  };

  const contentModelForEdit = (address: Address) => {
    setShowContentModalForEdit(true);
    setSelectedAddress(address);
  };

  const contentModelForDelete = (address: Address) => {
    setShowContentModalForDelete(true);
    setSelectedAddress(address);
  };

  const handleContentModelClose = () => {
    setShowContentModalForEdit(false);
    setShowContentModalForDelete(false);
  };

  const handleContentModelEditConfirm = () => {
    setShowContentModalForEdit(false);
    if (!selectedAddress) return;
    handleSelectedForEdit();
  };

  const onDeleteTapped = () => {
    setShowContentModalForDelete(false);
    if (!selectedAddress) return;
    removeUserAddress({ addressId: selectedAddress.id })
      .then(() => {
        queryClient.invalidateQueries([key.RECIPIENT_SAVED_ADDRESS]);
        setSuccessMessage("Address successfully deleted.");
      })
      .catch((error) => {
        setErrorMessage(error);
      });
  };

  const handleRecurringOptionSelected = (updateAll: boolean = false) => {
    if (!selectedRecurringAddress || !selectedAddress) return;

    const updateData = { ...selectedRecurringAddress, updateRecurringBookingAddress: updateAll };
    updateUserAddress({ addressId: selectedAddress.id, data: updateData })
      .then(() => {
        queryClient.invalidateQueries([key.RECIPIENT_SAVED_ADDRESS]);
        setRecurringModalOpen(false);
        setSelectedRecurringAddress(null);
        setSuccessMessage("Address successfully updated.");
      })
      .catch((error) => setErrorMessage("Unable to update address"));
  };

  const onEditRecurringAddress = (data: any) => {
    if (checkIfEmpty(data)) return;
    setAddressModalOpen(false);
    setSelectedRecurringAddress(data);
    setTimeout(() => setRecurringModalOpen(true), 200);
  };

  const buildRow = (address: Address) => {
    return (
      <>
        <AddressItem
          address={address}
          key={address?.id}
          onEditClicked={() => contentModelForEdit(address)}
          onDeleteClicked={() => contentModelForDelete(address)}
        />
        <Divider />
      </>
    );
  };

  const emptyAdressSubtitle = () => {
    return (
      <Box
        maxWidth="352px"
        textAlign={TextAlign.center}
        fontFamily={FontFamily.OpenSans}
        fontSize={FontSize.F14}
      >
        {/* <Link
          font={FontFamily.OpenSans} fontColor={Colors.TurquoiseBlue}></Link> */}
        <Text color={Colors.DarkSteelGrey}>
          Add an address, and all saved addresses for this recipient will appear here.
        </Text>
      </Box>
    );
  };
  const onAddressSaved = (data: any) => {
    setAddressModalOpen(false);
    queryClient.invalidateQueries([key.RECIPIENT_SAVED_ADDRESS]);
  };

  return (
    <Box
      marginTop="43px"
      paddingLeft={isMobile ? "16px" : "80px"}
      paddingRight={isMobile ? "16px" : "80px"}
      paddingBottom="88px"
    >
      <Box
        flexDirection={FlexDirection.Column}
        gridGap={Spacing.S3}
        display={Display.Flex}
        marginBottom={Spacing.S6}
      >
        <Text
          font={FontFamily.Museo}
          weight={FontWeight.Bold}
          size={FontSize.F24}
          color={Colors.NightBlue}
        >
          Manage {recipient.firstName}'s booking addresses
        </Text>
        <Text
          font={FontFamily.Museo}
          weight={FontWeight.Medium}
          size={FontSize.F16}
          color={Colors.Dusk}
        >
          All the addresses associated with this recipient's bookings will be here.
        </Text>

        <ContentModal
          showCrossIcon={false}
          visible={showContentModalForEdit}
          onClose={handleContentModelClose}
          fullWidth={isMobile}
          title="Edit Address"
          actions={[
            <Button title="Cancel" type={ButtonType.outlined} onClick={handleContentModelClose} />,
            <Button
              title="Confirm"
              onClick={handleContentModelEditConfirm}
              type={ButtonType.secondary}
            />,
          ]}
          titleStyle={{ fontSize: FontSize.F22, fontWeight: FontWeight.Bold }}
          textStyle={{
            fontFamily: FontFamily.OpenSans,
            fontSize: FontSize.F16,
            fontWeight: FontWeight.Regular,
            textAlign: TextAlign.center,
          }}
          description="Please confirm if you’d like to edit this address. Editing the address here will update it in the main address page and remove it from this page, which only lists booking-specific addresses."
          divider={false}
        />
        <ContentModal
          showCrossIcon={false}
          visible={showContentModalForDelete}
          onClose={handleContentModelClose}
          fullWidth={isMobile}
          title="Delete Address"
          actions={[
            <Button title="Cancel" type={ButtonType.outlined} onClick={handleContentModelClose} />,
            <Button title="Confirm" onClick={onDeleteTapped} type={ButtonType.secondary} />,
          ]}
          titleStyle={{ fontSize: FontSize.F22, fontWeight: FontWeight.Bold }}
          textStyle={{
            fontFamily: FontFamily.OpenSans,
            fontSize: FontSize.F16,
            fontWeight: FontWeight.Regular,
            textAlign: TextAlign.center,
          }}
          description="Please confirm to delete this address. This will permanently remove it from the system."
          divider={false}
        />
      </Box>

      {!isLoading && !checkIfEmpty(addresses) ? (
        <Box
          style={{
            backgroundColor: Colors.White,
            borderRadius: "6px",
            border: `solid 1px ${Colors.VeryLightPink}`,
          }}
          maxWidth={isMobile ? "100%" : undefined}
          mt={isMobile ? 2 : undefined}
        >
          <Box ml="24px" mr="24px">
            <Each of={addresses} render={buildRow} />
          </Box>
          <AddressModal
            additionalLoading={isRecurringCheckLoading}
            open={addressModalOpen}
            existingAddress={selectedAddress}
            onClose={() => setAddressModalOpen(false)}
            onSaved={onAddressSaved}
            isRecurringAddress={hasRecurringBooking}
            onEditRecurringAddress={onEditRecurringAddress}
          />
          <RecurringAddressUpdateModal
            onClose={handleCloseRecurring}
            title="You have a recurring booking scheduled. Would you like to update the address?"
            open={recurringModalOpen}
            handleUpdate={handleRecurringOptionSelected}
          />
        </Box>
      ) : (
        <EmptyDataPlaceHolder title="No addresses saved" subtitle={emptyAdressSubtitle} />
      )}
    </Box>
  );
};

export default RecipientAddressSection;
