import React, { useCallback, useEffect, useState } from "react";
import { Button, Divider, TextField, Typography } from "@mui/material";
import { Box, Checkbox } from "@material-ui/core";
import { Search } from "@mui/icons-material";
import { withStyles } from "@material-ui/core/styles";

import { Colors } from "../../../constants/colors";
import AddressLocationPin from "../../../images/dropdown-arrow.svg";
import { makeStyles } from "@material-ui/core/styles";
import { dropdownStylesCombined } from "../../Dropdown";
import { useServiceRates } from "../../../hooks/services/rates.hooks";
import { checkIfEmpty } from "../../../utils/object";
import { ServiceRate } from "../../../models";
import Dialog from "../../Dialog";
import CaretLeft from "../../../images/24_carat-left.png";
import { useMobile } from "../../../hooks/mobile";
import {
  AlignItems,
  Display,
  FlexDirection,
  FontFamily,
  FontSize,
  FontWeight,
  JustifyContent,
  LineHeight,
  Spacing,
  TextAlign,
} from "../../v2/Styled/enum";
import { Text } from "../../v2/Styled";
import { DEFAULT_COUNTRY } from "../../../utils/country";
import { useUserStore } from "../../../stores/user";

const useStyles = makeStyles({
  root: {
    "& .MuiInput-underline:before": {
      border: "none",
    },
    "&:hover .MuiInput-underline:before": {
      border: "none",
    },
    "& .MuiInput-underline:after": {
      border: "none",
    },
  },
});

const StyledCheckbox = withStyles({
  root: {
    color: Colors.LightPeriwinkle, // Default unchecked color
    "&:hover": {
      color: Colors.TurquoiseBlue, // Hover color for unchecked state
    },
    "&$checked": {
      color: Colors.TurquoiseBlue, // Checked color
      "&:hover": {
        color: Colors.TurquoiseBlue, // Hover color when checked
      },
    },
  },
  checked: {}, // Necessary for targeting the checked state
})(Checkbox);

interface ServiceFilterModalProps {
  filter: any;
  services: any;
  isServicesLoading: boolean;
  handleFilterApplied: any;
  setShowServiceFilter: any;
  setSelectedServiceList: any;
}

const ServiceFilterModal = ({
  filter,
  services,
  isServicesLoading,
  handleFilterApplied,
  setShowServiceFilter,
  setSelectedServiceList,
}: ServiceFilterModalProps) => {
  filter.serviceType =
    typeof filter.serviceType === "string" ? [filter.serviceType] : filter.serviceType || [];
  const classes = useStyles();
  const [selectedServices, setSelectedServices] = useState<any[]>(() => {
    return filter.serviceType
      ? filter.serviceType.map((id: number) => ({
          value: id,
          title: "",
          checked: true,
        }))
      : [];
  });
  const [serviceOptions, setServiceOptions] = useState<any[]>([]);

  const getServiceOptions = useCallback(
    (serviceArr?: ServiceRate[]) => {
      const newServices = serviceArr || services;
      if (checkIfEmpty(newServices)) return [];
      return newServices.map(({ alias, id }: { alias: string; id: number }) => ({
        value: id,
        title: alias,
        checked: selectedServices.some((service) => service.value === id),
      }));
    },
    [services, selectedServices]
  );

  useEffect(() => {
    if (!isServicesLoading) {
      setServiceOptions(getServiceOptions());
      if (selectedServices.some((service) => !service.title || service.title === "")) {
        setSelectedServices(
          selectedServices
            .map((service) => {
              if (!service.title || service.title === "") {
                service.title = services.find(
                  ({ id }: { id: number }) => id === service.value
                )?.alias;
              }
              return service;
            })
            .filter(Boolean)
        );
      }
    }
  }, [services, isServicesLoading, setSelectedServices]);

  const handleClear = useCallback(() => {
    filter.serviceType = null;
    setSelectedServices([]);
    setSelectedServiceList([]);
    handleFilterApplied();
    setShowServiceFilter(false);
  }, [filter, handleFilterApplied, setShowServiceFilter]);

  const applyFilters = () => {
    handleFilterApplied(
      "serviceType",
      selectedServices.map((service) => service.value)
    );
    setShowServiceFilter(false);
  };

  const onServiceSelected = (event: React.ChangeEvent<HTMLInputElement>, service: any) => {
    const indexFound = selectedServices.findIndex(
      (serviceItem) => serviceItem.value === service.value
    );
    if (event?.target?.checked) {
      if (indexFound === -1) {
        service.checked = true;
        setNumberOfServiceSelected((prev) => prev + 1);
        setSelectedServices((prevSelectedServices) => [...prevSelectedServices, service]);
      }
    } else {
      if (indexFound > -1) {
        service.checked = false;
        setSelectedServices((prevSelectedServices) =>
          prevSelectedServices.filter((_, i) => i !== indexFound)
        );
        setNumberOfServiceSelected((prev) => prev - 1);
      }
    }
  };

  useEffect(() => {
    if (selectedServices.length !== filter?.serviceType?.length) {
      filter.serviceType = selectedServices.map((service) => service.value);
      setSelectedServiceList(
        selectedServices
          .map((service) => service.title)
          .sort((a, b) => (a.length > b.length ? 1 : -1))
      );
    }
  }, [selectedServices, filter, setSelectedServiceList]);

  const searchService = useCallback(
    (searchText: string) => {
      const filteredServiceOptions: ServiceRate[] = services.filter(
        ({ alias }: { alias: string }) => alias.toLowerCase().includes(searchText)
      );
      setServiceOptions(getServiceOptions(filteredServiceOptions));
    },
    [services, getServiceOptions]
  );
  const isMobile = useMobile();
  const [open, setOpen] = useState(true);
  const [numberOfServiceSelected, setNumberOfServiceSelected] = useState(
    selectedServices.length || 0
  );

  return (
    <>
      {isMobile ? (
        <>
          <Dialog open={open} fullScreen={true}>
            <Box bgcolor={Colors.White} paddingX={Spacing.S6}>
              <Box
                height="52px"
                display={Display.Flex}
                flexDirection={FlexDirection.Row}
                alignItems={AlignItems.center}
                marginBottom={Spacing.S2}
              >
                <Box
                  height="48px"
                  width="48px"
                  display={Display.Flex}
                  justifyContent={JustifyContent.start}
                  alignItems={AlignItems.center}
                  onClick={() => {
                    setOpen(!open);
                    setShowServiceFilter(false);
                  }}
                >
                  <img src={CaretLeft} alt="Back" />
                </Box>
                <Box
                  height="48px"
                  width="100%"
                  display={Display.Flex}
                  justifyContent={JustifyContent.center}
                  alignItems={AlignItems.center}
                  textAlign={TextAlign.center}
                >
                  <Text
                    font={FontFamily.Museo}
                    weight={FontWeight.Bold}
                    size={FontSize.F16}
                    lineHeight={LineHeight.L24}
                    color={Colors.Indigo}
                  >
                    {numberOfServiceSelected === 0
                      ? "No services selected"
                      : `${numberOfServiceSelected} services selected`}
                  </Text>
                </Box>
              </Box>
              <Box height="73px" width="100%" display={Display.Flex} alignItems={AlignItems.center}>
                <TextField
                  fullWidth={true}
                  variant="outlined"
                  placeholder="Search services"
                  className={classes.root}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      fontFamily: FontFamily.Museo,
                      fontSize: FontSize.F16,
                      color: Colors.TurquoiseBlue,
                      borderRadius: "100px",
                      height: "56px",
                      marginBottom: Spacing.S4,
                      "& fieldset": {
                        border: `1px solid ${Colors.LightPeriwinkle}`,
                      },
                      "&:hover fieldset": {
                        borderColor: Colors.TurquoiseBlue,
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: Colors.TurquoiseBlue,
                      },
                    },
                    "& .MuiOutlinedInput-input": {
                      "&::placeholder": {
                        color: Colors.LightPeriwinkle, // Custom placeholder color
                        opacity: 1, // Ensures the placeholder is fully visible
                      },
                    },
                  }}
                  InputProps={{
                    startAdornment: (
                      <Search
                        height={16}
                        width={16}
                        style={{ marginRight: 8, color: Colors.Grey }}
                      />
                    ),
                  }}
                  size="small"
                  onChange={(e) => searchService(e.target.value)}
                />
              </Box>
              <Box display={"flex"} flexDirection={"column"} gridGap={8}>
                {serviceOptions.map(
                  (service: { value: number; title: string; checked: boolean | undefined }) => (
                    <Box
                      key={service.value}
                      style={{
                        fontFamily: FontFamily.Museo,
                        fontSize: FontSize.F16,
                        color: Colors.DarkSteelGrey,
                      }}
                    >
                      <StyledCheckbox
                        style={{
                          margin: 0,
                          padding: 0,
                          height: 24,
                          width: 24,
                          fontSize: FontSize.F24,
                        }}
                        inputProps={{
                          height: 24,
                          width: 24,
                        }}
                        value={service.value}
                        checked={service.checked}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                          onServiceSelected(event, service);
                        }}
                      />{" "}
                      <Text
                        sx={{
                          fontFamily: FontFamily.Museo,
                          fontSize: FontSize.F14,
                          color: Colors.DarkSteelGrey,
                        }}
                      >
                        {service.title}
                      </Text>
                    </Box>
                  )
                )}
              </Box>
            </Box>
          </Dialog>
        </>
      ) : (
        <Box
          position="absolute"
          style={{ ...dropdownStylesCombined, marginTop: Spacing.S4 }}
          padding={Spacing.S6}
          minWidth={600}
        >
          <Typography
            style={{
              fontFamily: FontFamily.Museo,
              fontWeight: FontWeight.Bold,
              fontSize: FontSize.F16,
              lineHeight: Spacing.S6,
              color: Colors.DarkSteelGrey,
              marginBottom: Spacing.S4,
            }}
          >
            All services
          </Typography>
          <TextField
            variant="outlined"
            placeholder="Search services"
            fullWidth
            sx={{
              "& .MuiOutlinedInput-root": {
                fontFamily: FontFamily.Museo,
                fontSize: FontSize.F16,
                color: Colors.TurquoiseBlue,
                borderRadius: "100px",
                height: "56px",
                marginBottom: Spacing.S4,
                "& fieldset": {
                  border: `1px solid ${Colors.LightPeriwinkle}`,
                },
                "&:hover fieldset": {
                  borderColor: Colors.TurquoiseBlue,
                },
                "&.Mui-focused fieldset": {
                  borderColor: Colors.TurquoiseBlue,
                },
              },
              "& .MuiOutlinedInput-input": {
                "&::placeholder": {
                  color: Colors.LightPeriwinkle, // Custom placeholder color
                  opacity: 1, // Ensures the placeholder is fully visible
                },
              },
            }}
            InputProps={{
              startAdornment: (
                <Search height={16} width={16} style={{ marginRight: 8, color: Colors.Grey }} />
              ),
            }}
            size="small"
            onChange={(e) => searchService(e.target.value)}
          />
          <Box display={"flex"} flexDirection={"row"} flexWrap={"wrap"} gridGap={8}>
            {serviceOptions.map(
              (service: { value: number; title: string; checked: boolean | undefined }) => (
                <Box
                  key={service.value}
                  flexBasis="45%"
                  style={{
                    fontFamily: FontFamily.Museo,
                    fontSize: FontSize.F16,
                    color: Colors.DarkSteelGrey,
                  }}
                >
                  <StyledCheckbox
                    style={{
                      margin: 0,
                      padding: 0,
                      height: 24,
                      width: 24,
                      fontSize: FontSize.F24,
                    }}
                    inputProps={{
                      height: 24,
                      width: 24,
                    }}
                    value={service.value}
                    checked={service.checked}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      onServiceSelected(event, service);
                    }}
                  />{" "}
                  <Text
                    sx={{
                      fontFamily: FontFamily.Museo,
                      fontSize: FontSize.F14,
                      color: Colors.DarkSteelGrey,
                    }}
                  >
                    {service.title}
                  </Text>
                </Box>
              )
            )}
          </Box>
          <Divider sx={{ marginY: 2 }} />
          <Box display={"flex"} justifyContent="space-between">
            <Button
              variant="text"
              sx={{
                fontFamily: FontFamily.Museo,
                color: filter.serviceType ? Colors.TextDanger : Colors.Grey,
                textTransform: "none",
                fontWeight: FontWeight.Bold,
              }}
              onClick={handleClear}
            >
              Clear all
            </Button>
            <Button
              variant="contained"
              sx={{
                backgroundColor: Colors.TurquoiseBlue,
                fontFamily: FontFamily.Museo,
                color: Colors.White,
                textTransform: "none",
                fontWeight: FontWeight.Bold,
                "&:hover": {
                  backgroundColor: Colors.TurquoiseBlue,
                  boxShadow: "none",
                },
                boxShadow: "none",
              }}
              onClick={applyFilters}
            >
              Apply
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default function ServicesFilter({
  filter,
  handleFilterApplied,
  stylesProps,
  setCloseOthers,
  closeOthers,
}: {
  filter: any;
  handleFilterApplied: any;
  stylesProps?: React.CSSProperties;
  setCloseOthers?: any;
  closeOthers?: boolean | string;
}) {
  const { user } = useUserStore();
  filter.serviceType =
    typeof filter.serviceType === "string" ? [filter.serviceType] : filter.serviceType;
  let countryCode = user?.country || DEFAULT_COUNTRY;
  if (filter.location) {
    // @ts-ignore
    countryCode =
      typeof filter.location === "string"
        ? JSON.parse(filter.location)?.countryCode
        : filter.location?.countryCode;
  }
  const { services, isLoading: isServicesLoading } = useServiceRates(countryCode, false);
  const [showServiceFilter, setShowServiceFilter] = useState(false);
  const [selectedServiceList, setSelectedServiceList] = useState<any[]>([]);
  const isMobile = useMobile();

  useEffect(() => {
    if (filter.serviceType && services.length) {
      setSelectedServiceList(
        filter.serviceType
          .map(
            (serviceId: number) =>
              services.find(({ id }: { id: number }) => id === serviceId)?.alias
          )
          .filter(Boolean)
          .sort((a: string, b: string) => (a.length > b.length ? 1 : -1))
      );
    }
  }, [services, filter.serviceType]);

  useEffect(() => {
    if (closeOthers !== "ServicesFilter") {
      setShowServiceFilter(false);
    }
  }, [closeOthers]);
  return (
    <Box position="relative">
      <Button
        variant={isMobile ? "text" : "outlined"}
        endIcon={
          <img
            src={AddressLocationPin}
            color={Colors.Grey}
            style={{
              marginRight: 8,
              color: Colors.Grey,
              position: "absolute",
              right: 10,
              marginTop: -3,
            }}
            alt={"Services"}
          />
        }
        title={selectedServiceList.join(" | ")}
        sx={{
          height: Spacing.S11,
          width: "100%",
          fontFamily: FontFamily.Museo,
          fontSize: FontSize.F16,
          fontWeight: FontWeight.Medium,
          color: selectedServiceList.length ? Colors.TurquoiseBlue : Colors.Grey,
          borderColor: Colors.LightBlueGrey,
          padding: "0px 24px",
          borderRadius: "100px",
          textTransform: "none",
          textAlign: "left",
          justifyContent: "space-between",
          whiteSpace: "nowrap",
          "&:hover": {
            borderColor: Colors.LightBlueGrey,
          },
          "&:focus-visible": {
            borderColor: Colors.LightBlueGrey,
          },
          ...stylesProps,
        }}
        onClick={() => {
          setShowServiceFilter(!showServiceFilter);
          setCloseOthers && setCloseOthers("ServicesFilter");
        }}
      >
        {selectedServiceList.length
          ? selectedServiceList.length > 2
            ? selectedServiceList.slice(0, 2).join(" | ") +
              " & " +
              (selectedServiceList.length - 2) +
              " more"
            : selectedServiceList.join(" | ")
          : isMobile
            ? "Select"
            : "All services"}
      </Button>
      {showServiceFilter && (
        <ServiceFilterModal
          filter={filter}
          services={services}
          isServicesLoading={isServicesLoading}
          handleFilterApplied={handleFilterApplied}
          setShowServiceFilter={setShowServiceFilter}
          setSelectedServiceList={setSelectedServiceList}
        />
      )}
    </Box>
  );
}
